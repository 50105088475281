// --- Vendors ---
import Isotope from 'isotope-layout';
var jQueryBridget = require('jquery-bridget');
// --- Components ---
const annuaire = {
    readyFn: function () {
        jQueryBridget('isotope', Isotope, $);
        var $grid = $('.grid-user').isotope({
            itemSelector: '.cell-user',
            layoutMode: 'fitRows'
        });

        $(".tag-list").on("click", ".tag", function () {
            $(".tag").removeClass("actif");
            $(this).addClass("actif");
            var filterValue = $(this).attr("data-filter");
            $grid.isotope({
                filter: filterValue
            });
        });

        $(".select-filtre").on("change", function () {
            var filterValue = $(this).val();
            $grid.isotope({
                filter: filterValue
            });
        });
    }
}
export { annuaire };