// --- Vendors ---
// --- Components ---
const post = {
    readyFn: function () {
        const lightbox = GLightbox({
            loop: true,
        });

    }
}
export { post };